import React from 'react';
// import { useStaticQuery, graphql } from "gatsby";
// import Img from "gatsby-image";

import PropTypes from 'prop-types';
// import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
// import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
// import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';
// import Images from '../../../../../landing-gatsby/src/components/image';

import VisionSectionWrapper from './vision.style';
import Vision from '../../../assets/image/donuts/vision.png';
import VisionText from '../../../assets/image/donuts/vision_text.svg';
import VisionMobileText from '../../../assets/image/donuts/vision_text_mobile.svg';
import StyledInnerWrapper from './vision.style';
import BackgroundImage from 'gatsby-background-image'

const VisionSection = ({
  row,
  col,
  // title,
  // description,
  // button,
  textArea,
  imageArea,
  blankArea,
  imagedata,
  className,
}) => {
//   const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//       placeholderImage: file(relativePath: { eq: "hubble/sound.png" }) {
//         childImageSharp {
//           fixed(width: 300) {
//             ...GatsbyImageSharpFixed
//           }
//         }
//       }
//   }
// `)
const sources = [
  imagedata.visionMobileImage.childImageSharp.fluid,
  {
    ...imagedata.visionDesktopImage.childImageSharp.fluid,
    media: `(min-width: 768px)`,
  },
]
  return (
    <VisionSectionWrapper>
      <BackgroundImage
      Tag={`section`}
      id={`media-vision`}
      className={className}
      fluid={sources}
    >
      <StyledInnerWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea}>
          {/* <Text
              {...description}
              content={data.site.siteMetadata.title}
            /> */}
          {/* <Image src={VisionText} alt="Greate Display" /> */}
          {/* <FloatingImage
          imageMobile={data.floatingImageMobile.childImageSharp.fixed}
          imageDesktop={data.floatingImageMobile.childImageSharp.fixed}
          /> */}
          {/* <Images /> */}
          
          <img className="desktop" src={VisionText} />
          <img className="mobile" src={VisionMobileText} />
          </Box>
          <Box {...col} {...imageArea}>
          <Box {...blankArea}><p></p></Box>

            {/* <Image /> */}
            {/* <DisplayImage /> */}
            {/* <Img fluid={this.data.placeholderImage.childImageSharp.fluid} /> */}
            {/* <img src={Vision} alt="Greate Display" /> */}
          </Box>
        </Box>
      </Container>
      </StyledInnerWrapper>
      </BackgroundImage>
    </VisionSectionWrapper>
  );
};

VisionSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  blankArea: PropTypes.object,
};

VisionSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'bottom',
    // justifyContent: 'center',
    // flexDirection: 'row',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '43%', '38%', '30%'],
    // width: ['80%', '80%', '80%', '90%', '100%'],
    mt: ['60%', '60%', '0', '0', '0'],
    pt: ['0', '0', '0', '0', '10%'],
    // mb: ['20%', '20%', '0', '0', '0'],
    alignItems: 'flex-start',
  },
  imageArea: {
    width: ['100%', '100%', '57%', '62%', '70%'],
    // width: ['80%', '80%', '80%', '90%', '100%'],
    // height: ['80%', '80%', '80%', '624px', '624px'],
    // mb: ['0', '0', '0', '0', '0'],
  },
  blankArea: {
    width: '100%',
    height: '0',
    pb: ['0%', '0%', '70%', '70%', '100%'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    fontSize: '16px',
    color: '#000',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default VisionSection;
// export default props => (
//   <StaticQuery
//   query={graphql`
//   query {
//     floatingImageMobile: file(relativePath: { eq: "hubble/vision.png" }) {
//         childImageSharp {
//           fixed(width: 120) {
//             ...GatsbyImageSharpFixed
//           }
//         }
//     }
//   }
// `}
//   render={data => <VisionSection data={data} {...props} />}
//   />
// )