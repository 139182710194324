import styled from 'styled-components';
// import BgImage from '../../../assets/image/donuts/sound.png';
// background-color: #fff;
// background-image: url(${BgImage});
// background-repeat: no-repeat;
// background-position: 25% center;
// background-size: 100vw auto;

const SoundSectionWrapper = styled.section`
  padding: 120px 0;

  #media-sound::before,
  #media-sound::after {
  /*width: 100%;
  min-height: 100vh;*/
  /* You should set a background-size as the default value is "cover"! */
  background-size: 100vw auto;
  background-position:  top;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
  }

  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 990px) {
    padding: 100px 0;
    .desktop {
      display: block !important;
    }
    .mobile {
      display: none !important;
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    padding: 80px 0;
    .desktop {
      display: none !important;
    }
    .mobile {
      display: block !important;
      margin: auto;
    }
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export default SoundSectionWrapper;

export const StyledInnerWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;