import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasThreeTheme } from 'common/src/theme/saasThree';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/donuts/saasThree.style';

import BannerSection from 'common/src/containers/donuts/Banner';
import Navbar from 'common/src/containers/donuts/Navbar';
// import ServiceSection from 'common/src/containers/donuts/Service';
import DisplaySection from 'common/src/containers/donuts/Display';
import SoundSection from 'common/src/containers/donuts/Sound';
import ConnectionSection from 'common/src/containers/donuts/Connection';
import VisionSection from 'common/src/containers/donuts/Vision';
import PixelSection from 'common/src/containers/donuts/Pixel';
import CustVisionSection from 'common/src/containers/donuts/CustVision';
// import PricingSection from 'common/src/containers/donuts/Pricing';
// import PartnerSection from 'common/src/containers/donuts/Partner';
import TrialSection from 'common/src/containers/donuts/Trial';
import FeatureSection from 'common/src/containers/donuts/Feature';
// import UpdateScreen from 'common/src/containers/donuts/UpdateScreen';
// import TestimonialSection from 'common/src/containers/donuts/Testimonial';
import Newsletter from 'common/src/containers/gojoylab/Newsletter';
import Footer from 'common/src/containers/gojoylab/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';

export default ( {data} ) => {
  return (
    <ThemeProvider theme={saasThreeTheme}>
      <Fragment>
        <SEO title="GojoyLab | VR Donuts" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <DisplaySection imagedata = {data}/>
          <CustVisionSection imagedata = {data}/>
          <FeatureSection imagedata = {data}/>
          <SoundSection imagedata = {data}/>
          <ConnectionSection imagedata = {data}/>
          <VisionSection imagedata = {data}/>
          <PixelSection imagedata = {data}/>
          {/* <ServiceSection />
          <UpdateScreen />
          <FeatureSection />
          <PartnerSection />
          <PricingSection />
          <TestimonialSection /> */}
          <TrialSection />
          <Newsletter />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

// import Layout from "../components/layout";
// // import VisionImage from 'common/src/containers/donuts/Vision/visionImage';
// import Image from "../components/image";

// export default () => {
//   return (
//     <Layout>
//       <VisionSection />
//     </Layout>
//   )
// };
export const query = graphql`
  query {
    displayMobileImage: file(relativePath: { eq: "donuts/light.png" }) {
      childImageSharp {
        fluid(maxWidth: 460, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    displayDesktopImage: file(
      relativePath: { eq: "donuts/light.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 815, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    custvisionMobileImage: file(relativePath: { eq: "donuts/custvision.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    custvisionDesktopImage: file(
      relativePath: { eq: "donuts/custvision.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    fiberMobileImage: file(relativePath: { eq: "donuts/feature_fiber.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fiberDesktopImage: file(
      relativePath: { eq: "donuts/feature_fiber.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 280, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    softMobileImage: file(relativePath: { eq: "donuts/feature_soft.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    softDesktopImage: file(
      relativePath: { eq: "donuts/feature_soft.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 280, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }             
    soundMobileImage: file(relativePath: { eq: "donuts/sound.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    soundDesktopImage: file(
      relativePath: { eq: "donuts/sound.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 850, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connMobileImage: file(relativePath: { eq: "donuts/connection.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connDesktopImage: file(
      relativePath: { eq: "donuts/connection.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    visionMobileImage: file(relativePath: { eq: "donuts/vision.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    visionDesktopImage: file(
      relativePath: { eq: "donuts/vision.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pixelMobileImage: file(relativePath: { eq: "donuts/pixel.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pixelDesktopImage: file(
      relativePath: { eq: "donuts/pixel.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`