import styled from 'styled-components';
// import BgImage from '../../../assets/image/saasThree/map.png';

const FeatureSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #fff;

  img {
    margin: auto;
  }
  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export default FeatureSectionWrapper;
