import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import FeatureSectionWrapper from './feature.style';
import FeatureFiber from '../../../assets/image/donuts/feature_fiber.png';
import FeatureSoft from '../../../assets/image/donuts/feature_soft.png';
import FloatingImage from "../../../components/FloatingImage";

const FeatureSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  imagedata,
}) => {
  return (
    <FeatureSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...imageArea}>
            {/* <Image src={FeatureFiber} alt="Material Fiber Image" /> */}
            <FloatingImage
            imageMobile={imagedata.fiberMobileImage.childImageSharp.fluid}
            imageDesktop={imagedata.fiberDesktopImage.childImageSharp.fluid}
            />
            <Text
              {...description}
              content="Fiber Material"
            />
            <Text
              {...description}
              content="Skin Friendly"
            />
          </Box>
          <Box {...col} {...textArea}>
            {/* <Image src={FeatureSoft} alt="Material Soft Image" /> */}
            <FloatingImage
            imageMobile={imagedata.softMobileImage.childImageSharp.fluid}
            imageDesktop={imagedata.softDesktopImage.childImageSharp.fluid}
            />
            <Text
              {...description}
              content="Microfiber Leather"
            />
            <Text
              {...description}
              content="Soft and Breathable"
            />
            
          </Box>
        </Box>
      </Container>
    </FeatureSectionWrapper>
  );
};

FeatureSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

FeatureSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'column',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '40%', '40%', '40%', '30%'],
    m: ['auto'],
  },
  imageArea: {
    width: ['100%', '40%', '40%', '40%', '30%'],
    mb: ['40px', '0', '0', '0', '0'],
    m: ['auto'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['26px', '26px', '30px', '32px', '32px'],
    color: '#000',
    lineHeight: '1.05',
    mt: '20px',
    // mb: '33px',
    // pl: '18%',
    // pr: '18%',
    textAlign: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default FeatureSection;
