import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import SoundSectionWrapper from './sound.style';
import Sound from '../../../assets/image/donuts/sound.png';
import SoundText from '../../../assets/image/donuts/SURROUND_SOUND_TEXT.svg';
import StyledInnerWrapper from './sound.style';
import BackgroundImage from 'gatsby-background-image'

const SoundSection = ({
  row,
  col,
  // title,
  description,
  // button,
  textArea,
  imageArea,
  blankArea,
  imagedata,
  className,
}) => {
  const sources = [
    imagedata.soundMobileImage.childImageSharp.fluid,
    {
      ...imagedata.soundDesktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <SoundSectionWrapper>
      <BackgroundImage
      Tag={`section`}
      id={`media-sound`}
      className={className}
      fluid={sources}
    ><StyledInnerWrapper>
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea}>
          <Text
              {...description}
              content=""
            />
          {/* <Image src={SoundText} alt="Greate Display" /> */}
          </Box>
          <Box {...col} {...imageArea}>
            {/* <Image /> */}
            {/* <DisplayImage /> */}
            {/* <Img fluid={this.data.placeholderImage.childImageSharp.fluid} /> */}
            <Box {...blankArea}><p></p></Box>
            <Image src={SoundText} alt="Greate Display" />
          </Box>
        </Box>
      </Container>
      </StyledInnerWrapper>
      </BackgroundImage>
    </SoundSectionWrapper>
  );
};

SoundSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  blankArea: PropTypes.object,
};

SoundSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'bottom',
    // justifyContent: 'center',
    // flexDirection: 'row',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: '50%',
    // mt: ['-160px', '-160px', '-160px', '-180px', '-200px'],
  },
  imageArea: {
    width: '50%',
    mb: ['40px', '40px', '40px', '0', '0'],
  },
  blankArea: {
    width: '100%',
    height: '0',
    pb: ['60%', '70%', '80%', '90%', '100%'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    fontSize: '16px',
    color: '#000',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default SoundSection;